import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import PaymentPage from "./PaymentPage";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'; // Make sure to install axios

class Signup extends Component {
  constructor() {
    super();
    this.state = {
      teamName: '',
      teamLeader: { name: '', email: '' },
      teamMembers: [],
      errorMessage: '',
      isSignupSuccessful: false,
      paymentDetails: null
    };
  }

  addTeamMember = () => {
    this.setState(prevState => ({
      teamMembers: [...prevState.teamMembers, { name: '', email: '' }]
    }));
  };

  deleteTeamMember = (index) => {
    this.setState(prevState => ({
      teamMembers: prevState.teamMembers.filter((member, i) => i !== index)
    }));
  };

 handleInputChange = (e, index) => {
    const { name, value } = e.target;
    
    if (name === 'teamName') {
      this.setState({ teamName: value });
    } else if (name === 'leaderName' || name === 'leaderEmail') {
      this.setState(prevState => ({
        teamLeader: {
          ...prevState.teamLeader,
          [name === 'leaderName' ? 'name' : 'email']: value
        }
      }));
    } else {
      const teamMembers = [...this.state.teamMembers];
      teamMembers[index][name] = value;
      this.setState({ teamMembers });
    }
  };

  signup = (event) => {
    event.preventDefault();

    const data = {
      teamName: this.state.teamName,
      teamLeader: this.state.teamLeader,
      teamMembers: this.state.teamMembers
    };

    axios.post('https://3e5yu7zdeut5by5k7mlm3sesia0iftbx.lambda-url.eu-west-1.on.aws/', data)
      .then(response => {
        console.log('Signup successful', response.data);
        this.setState({ 
          isSignupSuccessful: true,
          paymentDetails: response.data
        });
      })
      .catch(error => {
        console.error('Error during signup', error);
        this.setState({ errorMessage: 'Désolé, quelque chose n\'a pas fonctionné. Essayez à nouveau ou contactez hello@rallyehiver.fr pour de l\'aide.' });
      });
  };


  render() {

    if (this.state.isSignupSuccessful) {
      return <PaymentPage paymentDetails={this.state.paymentDetails} />; // Pass paymentDetails as a prop
    }

    return (
      <div>
        <h2>Inscriptions</h2>
        
        <section>

          {/* Display error message if it exists */}
          {this.state.errorMessage && <p id="errorMessage">{this.state.errorMessage}</p>}

          <p>
            Pour participer au Rallye d'Hiver 2024, formez une équipe (habituellement de 2 à 6 personnes), donnez-lui un nom, désignez un chef d'équipe et réglez les frais d'inscription.
          </p>
          <p>
            Les frais d'inscription sont de 25€ par équipe, quel que soit le nombre de participants. Cette somme sert essentiellement à rembourser les coûts d'organisation et à acheter des lots pour récompenser toutes les équipes. Les modalités de remise des prix seront annoncées ultérieurement.
          </p>

          <form id="signup-form" onSubmit={this.signup}>
            <input
              type="text"
              name="teamName"
              placeholder="Nom de votre équipe"
              value={this.state.teamName}
              onChange={this.handleInputChange}
              required
            />

            <div class="teamLeader">
              <input
                type="text"
                name="leaderName"
                placeholder="Nom du chef d'équipe"
                value={this.state.teamLeader.name}
                onChange={this.handleInputChange}
                required
              />
              <input
                type="email"
                name="leaderEmail"
                placeholder="E-mail du chef d'équipe"
                value={this.state.teamLeader.email}
                onChange={this.handleInputChange}
                required
              />
            </div>
            
            {this.state.teamMembers.map((member, index) => (
              <div key={index} class="teamMember">
                <button id="deleteTeamMember" type="button" onClick={() => this.deleteTeamMember(index)}>
                  X
                </button>
                <input
                  type="text"
                  name="name"
                  placeholder="Nom du co-équipier"
                  value={member.name}
                  onChange={e => this.handleInputChange(e, index)}
                  required
                />
                <input
                  type="email"
                  name="email"
                  placeholder="E-mail du co-équipier"
                  value={member.email}
                  onChange={e => this.handleInputChange(e, index)}
                  required
                />
              </div>
            ))}
            
            <nav>

            <button id="addTeamMember" type="button" onClick={this.addTeamMember}>Ajouter un co-équipier</button>
            
             <input id="ValidationButton" type="submit" value="Inscrire cette équipe" />

            </nav>

          </form>
        </section>

        <nav>
          <NavLink to="/"> <button> <FontAwesomeIcon icon="arrow-left" /> Retour</button> </NavLink>
        </nav>
      </div>
    );
  }
}

export default Signup;
