import React, { Component } from "react";
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';

class PaymentPage extends Component {
  redirectToStripePayment = async () => {
    try {
      // Define the necessary constants
      const createCheckoutSessionUrl = 'https://7dgldtlbqkzusaqp5z45py27vi0cpodp.lambda-url.eu-west-1.on.aws';
      //const createCheckoutSessionUrl = 'https://h34xj6fxihijqfhs27dubbj2p40fmmhf.lambda-url.eu-west-1.on.aws';
      const successUrl = '#/allgood';
      const cancelUrl = '#/payment';
      const { teamEmail, login } = this.props.paymentDetails;
      const stripeAccount = 'acct_1LGD1a2E4Pf8JclV';

      // Define the request body
      const requestBody = {
        //priceid: 'price_1OBxCG2E4Pf8JclVfoAYqpA3',
        priceid: 'price_1OBmVd2E4Pf8JclVAFDmwE1W',
        stripeaccount: stripeAccount,
        successUrl,
        cancelUrl,
        customerEmail: teamEmail,
        customerId: login
      };

      // Create a Checkout Session
      const response = await axios.post(createCheckoutSessionUrl, requestBody);
      const sessionId = response.data.id;

      // Load Stripe.js
      const stripe = await loadStripe('pk_live_51LGBvIFydABlduG3Yx6axMVdv4LQ6DQljmkAxQeZPkZuJSVgMeTJoHc4C26BwpjO15ie3lcXDOzhJHwcLL7r7KfB00b7HtQ4y1', {
      //const stripe = await loadStripe('pk_test_51LGBvIFydABlduG3zSShZpIbNkIUyfJSsbFUEvzwjp7Gp4R4EyAs1w2cYb1vdxlGVbJ36WHjIBF1PhGtKFQWyPwW00KtaMO0be', {
        stripeAccount: stripeAccount
      });



      // Redirect to Stripe Checkout
      if (stripe) {
        stripe.redirectToCheckout({ sessionId });
      }

    } catch (error) {
      console.error('An error occurred while redirecting to Stripe Checkout:', error);
      // You might want to display an error message to the user
    }
  };

  render() {
    return (
      <div>
        <h2>Frais d'inscription</h2>
        
        <section>
          <p>Merci !</p>
          <p>Votre équipe <span className="t-name"></span> est pré-inscrite.</p>
          <p>Pour confirmer votre inscription, il vous suffit de régler vos frais d'inscription. Ceux-ci ne sont à régler qu'une seule fois par équipe et servent uniquement à couvrir les coûts d'organisation.</p>
          <p>Après paiement, vous recevrez un mail de confirmation contenant la facture. En cas de difficultés écrivez-nous sur <b>hello@rallyehiver.fr</b></p>
        </section>

        <button onClick={this.redirectToStripePayment}>Payer par carte bancaire</button>
      </div>
    );
  }
}

export default PaymentPage;
