import React from "react";
import YearEdition from "./YearEdition";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Editions = () => (
	<div>
		<h2>Les précédentes éditions</h2>

		<section className="editions-container">
			<YearEdition year="2024" theme="Les Jeux Olympiques" url="https://2024.rallyehiver.fr" />
			<YearEdition year="2023" theme="L'école" url="https://2023.rallyehiver.fr" />
			<YearEdition year="2022" theme="Jardins et oiseaux" url="https://rallyehiver2022.fr/?code=spzowsqj" />
			<YearEdition year="2021" theme="Les méchants" url="" />
			<YearEdition year="2020" theme="Le temps" url="https://2020.rallyehiver.fr" />
			<YearEdition year="2019" theme="Les jeux" url="https://2019.rallyehiver.fr" />
			<YearEdition year="2018" theme="Les romans" url="" />
			<YearEdition year="2017" theme="Les faits divers" url="" />
			<YearEdition year="2016" theme="Le luxe" url="https://2016.rallyehiver.fr" />
			<YearEdition year="2015" theme="Les dames" url="" />
			<YearEdition year="2014" theme="Les explorateurs" url="" />
			<YearEdition year="2013" theme="Les pierres" url="" />
			<YearEdition year="2012" theme="La musique" url="https://2012.rallyehiver.fr" />
			<YearEdition year="2011" theme="Les trains" url="" />
			<YearEdition year="2010" theme="L'Hydro-rallye" url="" />
			<YearEdition year="2009" theme="Rallye en-ique" url="" />
			<YearEdition year="2008" theme="Les jeux" url="" />
			<YearEdition year="2007" theme="Le roman policier" url="" />
			<YearEdition year="2006" theme="Rallye d'Hiver" url="" />
			<YearEdition year="2005" theme="Interplanétaire" url="" />
			<YearEdition year="2004" theme="Rallye d'Hiver" url="" />
			<YearEdition year="2003" theme="Squares, villas ..." url="" />
			<YearEdition year="2002" theme="Rallye d'Hiver" url="" />
			<YearEdition year="2001" theme="Les fromages" url="" />
			<YearEdition year="2000" theme="Rallye d'Hiver" url="" />
			<YearEdition year="1999" theme="Les Maisons" url="" />
			<YearEdition year="1998" theme="Les Musées" url="" />
			<YearEdition year="1997" theme="Passages et galeries" url="" />
		</section>

		<nav>
			<NavLink to="/"> <button> <FontAwesomeIcon icon="arrow-left" /> Retour</button> </NavLink>
		</nav>
    </div>
);

export default Editions;
