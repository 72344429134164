import React, { Component } from 'react';

class YearEdition extends Component {
  getImageSrc(year) {
    try {
      // Attempt to load the specific year image
      return require('./res/rallye' + year + '.jpg');
    } catch (e) {
      // If an error occurs (the file doesn't exist), return the default image
      return require('./res/rallye.jpg');
    }
  }

  render() {
    const imageSrc = this.getImageSrc(this.props.year);

    // The rest of your render code remains the same
    const content = (
      <div>
        <p>{this.props.year}<br />
        {this.props.theme}</p>
      </div>
    );

    if (this.props.url === '') {
      return (
        <div className="year-edition">
          <img src={imageSrc} alt="" />
          {content}
        </div>
      );
    } else {
      return (
        <a className="year-edition" href={this.props.url} target="_blank" rel="noopener noreferrer">
          <img src={imageSrc} alt="" />
          {content}
        </a>
      );
    }
  }
}

export default YearEdition;
