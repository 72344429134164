import React from "react";
import { Routes, Route, NavLink, HashRouter } from "react-router-dom";
import Editions from "./Editions";
import Home from "./Home";
import Contact from "./Contact";
import SignUp from "./SignUp";
import PaymentPage from "./PaymentPage";
import AllGood from "./AllGood";
import "./index.css";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

library.add(faArrowLeft)

export const Main = () => (
	<HashRouter>
		<div className="content">
			<NavLink to="/"> <h1>Rallye d'Hiver</h1> </NavLink>
			<Routes>
				<Route exact path="/" element={<Home />} />
				<Route path="/editions" element={<Editions />} />
				<Route path="/contact" element={<Contact />} />
				<Route path="/signup" element={<SignUp />} />
				<Route path="/payment" element={<PaymentPage />} />
				<Route path="/allgood" element={<AllGood />} />
			</Routes>
		</div>
    </HashRouter>
);

export default Main;
