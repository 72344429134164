import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const AllGood = () => (
  <div>
    <h2>Le compte est bon !</h2>

    <section>
      <p>Votre équipe <span className="t-name"></span> est maintenant bien inscrite au Rallye d'Hiver 2024, qui commencera le 21 décembre 2023. Vous recevrez une confirmation par mail, et bientôt de plus amples informations sur le début du rallye.</p>
      <p>À très bientôt !</p>
    </section>

    <nav>
      <NavLink to="/"> <button> <FontAwesomeIcon icon="arrow-left" /> Retour</button>      </NavLink>
    </nav>
  </div>
);

export default AllGood;